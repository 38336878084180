import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import NextButton from '../../button/NextButton';
import TextInput from '../../inputs/TextInput';

import mailIcon from '../../../resources/icons/mail-icon.svg';
import keyIcon from '../../../resources/icons/key-solid.svg';
import phoneIcon from '../../../resources/icons/phone-solid.svg';
import FormContainer from '../../inputs/FormContainer';

function Register() {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [password2, setPassword2] = useState();
  const [phone, setPhone] = useState();
  const history = useHistory();

  return (
    <div style={{
      display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%',
    }}
    >
      <FormContainer>
        <TextInput
          icon={mailIcon}
          type="text"
          value={email}
          placeholder="Your Email"
          handleTextChange={setEmail}
        />
        <TextInput
          icon={keyIcon}
          type="password"
          value={password}
          placeholder="Your Password"
          handleTextChange={setPassword}
        />
        <TextInput
          type="password"
          value={password2}
          placeholder="Confirm Password"
          handleTextChange={setPassword2}
        />
        <TextInput
          icon={phoneIcon}
          type="phone"
          value={phone}
          placeholder="Your Phone Number"
          handleTextChange={setPhone}
        />
      </FormContainer>
      <NextButton onClick={() => history.push('/verification')} />
    </div>
  );
}

export default Register;
