import React from 'react';
import Particles from 'react-particles-js';
import ParticleConfig from '../../resources/particle-config/particlesjs-config.json';

const BackgroundLayer = React.memo(({ children }) => (
  <div style={styles.background}>
    <Particles
      style={{
        position: 'fixed',
        left: '45%',
        top: '30%',
        zIndex: '1',
      }}
      params={ParticleConfig}
    />
    {children}
  </div>
));

const styles = {
  background: {
    background: 'linear-gradient(135.52deg, #000000 56.23%, #ED3036 122.18%)',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '0',
  },
};

export default BackgroundLayer;
