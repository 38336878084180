import React from 'react';
import { useTransition, config, animated } from 'react-spring';

function FadeTransition({ children }) {
  const transitionConfig = {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.slow,
    reset: false,
    delay: 500,
  };

  const transitions = useTransition(children, transitionConfig);

  return transitions(
    (transitionStyles, item) => (item
      && <animated.div style={{ ...styles.container, ...transitionStyles }}>{item}</animated.div>
    ),
  );
}

const styles = {
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    color: 'white',
  },
};

export default FadeTransition;
