import React from 'react';
import { useLocation } from 'react-router-dom';
import About from '../components/about/About';
import Login from '../components/authentication-flow/login/Login';
import QRcodeForm from '../components/authentication-flow/qrcode/QRcodeForm';
import Register from '../components/authentication-flow/register/Register';
import Verification from '../components/authentication-flow/verification/Verification';
import ContactUs from '../components/contact/ContactUs';
import useWindowDimensions from '../hooks/useWindowDimensions';
import HomePageFull from './home/HomePageFull';
import HomePageMobile from './home/HomePageMobile';

function HomePage() {
  const location = useLocation();
  const { isMobile } = useWindowDimensions();
  console.log(isMobile);

  let inputForm;
  switch (location?.pathname) {
    case '/login':
      inputForm = <Login />;
      break;
    case '/register':
      inputForm = <Register />;
      break;
    case '/verification':
      inputForm = <Verification />;
      break;
    case '/qrcode':
      inputForm = <QRcodeForm />;
      break;
    case '/about':
      inputForm = <About />;
      break;
    case '/contact':
      inputForm = <ContactUs />;
      break;
    default:
      inputForm = <Login />;
      break;
  }

  if (isMobile) return <HomePageMobile inputForm={inputForm} currentPath={location?.pathname} />;
  return (
    <HomePageFull inputForm={inputForm} currentPath={location?.pathname} />
  );
}

export default HomePage;
