import React from 'react';
import FormHeaderNav from './FormHeaderNav';
import FormHeaderTitle from './FormHeaderTitle';

function FormHeaderLinks({ currentPath }) {
  let formHeader = null;
  switch (currentPath) {
    case '/':
      formHeader = <FormHeaderNav />;
      break;
    case '/register':
      formHeader = <FormHeaderNav />;
      break;
    case '/verification':
      formHeader = <FormHeaderTitle title="Verification" />;
      break;
    case '/qrcode':
      formHeader = <FormHeaderTitle title="Code" />;
      break;
    case '/about':
      formHeader = <FormHeaderTitle title="about us" />;
      break;
    case '/contact':
      formHeader = null;
      break;
    default:
      break;
  }

  return (formHeader);
}

export default FormHeaderLinks;
