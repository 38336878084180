import React from 'react';

function About() {
  return (
    <div style={styles.container}>
      Divine Games is about developing the next generation of
      technologies to create sustainable architecture for gaming.
      We are focused on integrating with the existing gaming ecosystem
      and to become the source of truth within industry.  Our talented
      team has over 15+ years of experience in the video game and software
      development industry we are united in a mission to empower gamers and
      provide a new and unique vision for the gaming industry.
    </div>
  );
}

const styles = {
  container: {
    width: '65%',
    fontFamily: 'Montserrat',
    lineHeight: '1.5em',
    padding: '36px 12px',
  },
};

export default About;
