/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

function NavbarItem({ item }) {
  const history = useHistory();
  const location = useLocation();
  const [hovered, setHovered] = useState(false);
  const { title, to } = item;

  const isCurrentPage = location.pathname === to;

  const handleLink = (url) => {
    if (isCurrentPage) return;
    history.push(url);
  };

  return (
    <div
      style={styles.itemContainer}
      onClick={() => handleLink(to)}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <span style={hovered || isCurrentPage ? styles.itemTitleHovered : styles.itemTitle}>
        {title}
      </span>
    </div>
  );
}

const styles = {
  itemContainer: {
    justifyContent: 'flex-start',
    paddingRight: '2.5em',
    cursor: 'pointer',
    textDecoration: 'none',
  },
  itemTitle: {
    color: 'white',
    fontFamily: 'Elemental End',
    fontSize: '24px',
    transition: 'all 0.2s ease',
  },
  itemTitleHovered: {
    color: '#ED3036',
    fontFamily: 'Elemental End',
    fontSize: '24px',
    transition: 'all 0.2s ease',
  },
};

export default NavbarItem;
