/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';

function NextButton({ onClick }) {
  return (
    <div style={styles.buttonContainer} onKeyDown={() => {}} onClick={onClick}>
      Next &gt;
    </div>
  );
}

const styles = {
  buttonContainer: {
    cursor: 'pointer',
    color: 'white',
    fontSize: '26px',
    padding: '12px',
    fontFamily: 'Elemental End',
  },
};

export default NextButton;
