import React from 'react';

function FormContainer({ children }) {
  return (
    <div style={styles.formContainer}>
      {children}
    </div>
  );
}

const styles = {
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '3em 0',
    width: '70%',
  },
};

export default FormContainer;
