import React from 'react';
import Particles from 'react-particles-js';
import ParticleConfig from '../../resources/particle-config/particlesjs-config-mobile.json';

const BackgroundLayerMobile = React.memo(({ children }) => (
  <div style={styles.background}>
    <Particles
      style={{
        position: 'fixed',
        left: '0%',
        bottom: '0%',
        zIndex: '1',
      }}
      params={ParticleConfig}
    />
    {children}
  </div>
));

const styles = {
  background: {
    background: 'linear-gradient(180deg, #000000 0.01%, #ED3036 218.27%)',
    minHeight: '100vh',
    minWidth: '100vw',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '0',
  },
};

export default BackgroundLayerMobile;
