import React from 'react';
import FormHeaderLinks from '../../components/authentication-flow/form-header/FormHeaderLinks';
import BackgroundLayerMobile from '../../components/layout/BackgroundLayerMobile';
import FadeTransition from '../../components/transitions/FadeTransition';
import Navbar from '../../navigator/Navbar';

import DivineGamesBranding from '../../resources/branding/LR LOGO PNG-02.png';

function HomePageMobile({ currentPath, inputForm }) {
  return (
    <BackgroundLayerMobile>
      <div style={styles.column}>
        <div style={styles.blockOne}>
          <img style={styles.brandingImage} src={DivineGamesBranding} alt="Divine Games Branding" />
        </div>
        <div style={styles.blockTwo}>
          hi
        </div>
      </div>
    </BackgroundLayerMobile>
  );
}

const styles = {
  column: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 5,
    height: '100vh',
  },
  blockOne: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    color: 'white',
  },
  blockTwo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 3,
    color: 'white',
  },
  brandingImage: {
    width: '40%',
  },
};

export default HomePageMobile;
