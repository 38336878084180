/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import QRCode from 'qrcode';

import FormContainer from '../../inputs/FormContainer';
import ExampleQR from '../../../resources/images/exampleqr.png';
import NextButton from '../../button/NextButton';

function QRcodeForm() {
  const history = useHistory();
  const [copyClicked, setCopyClicked] = useState(false);

  const copyToClipboard = (text) => {
    setCopyClicked(true);
    navigator.clipboard.writeText(text);
  };

  return (
    <div style={{
      display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center',
    }}
    >
      <FormContainer>
        <img src={ExampleQR} alt="qrcode" />
        <div style={styles.manualCode}>O5WCCJSKOZWHMRJJFBTUOVRQG5KHI4RFHFND6SKFN5YXAT3CNBRA</div>
        <div
          style={styles.copyText}
          onClick={
            () => copyToClipboard('O5WCCJSKOZWHMRJJFBTUOVRQG5KHI4RFHFND6SKFN5YXAT3CNBRAO5WCCJSKOZWHMRJJFBTUOVRQG5KHI4RFHFND6SKFN5YXAT3CNBRA')
            }
        >
          {copyClicked ? 'added to clipboard!' : 'copy'}
        </div>
        <NextButton onClick={() => history.push('/')} />
      </FormContainer>
    </div>
  );
}

const styles = {
  manualCode: {
    fontFamily: 'Montserrat',
    fontSize: '11px',
    padding: '24px 0 12px 0',
  },
  copyText: {
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: '600',
    paddingBottom: '24px',
    cursor: 'pointer',
  },
};

export default QRcodeForm;
