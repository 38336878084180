import React, { useState } from 'react';

function TextInput({
  icon, placeholder = 'text here', type = 'text', value, handleTextChange,
}) {
  const [focused, setFocused] = useState(false);
  const selected = value || focused;

  return (
    <div style={styles.inputRow}>
      <div style={selected ? styles.iconStyle : { ...styles.iconStyle, opacity: 0.3 }}>
        {icon ? <img style={{ width: '36px' }} src={icon} alt="icon" /> : <div style={{ width: '36px' }} />}
      </div>
      <input
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        style={selected ? styles.inputStyle : { ...styles.inputStyle, borderBottom: '3px solid #E9E9EB33' }}
        type={type}
        value={value}
        placeholder={placeholder}
        onChange={(event) => handleTextChange(event.target.value)}
      />
    </div>
  );
}

const styles = {
  inputRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '90%',
    padding: '12px 0',
    marginRight: '48px',
  },
  iconStyle: {
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  inputStyle: {
    backgroundColor: 'transparent',
    fontFamily: 'Montserrat',
    fontWeight: '500',
    width: '100%',
    border: 'none',
    borderBottom: '3px solid #E9E9EB',
    color: 'white',
    fontSize: '24px',
    margin: '0px 24px',
    padding: '16px 24px',
    boxShadow: 'none',
    textAlign: 'center',
    outline: 'none !important',
    transition: '0.3s ease-in',
  },
};

export default React.memo(TextInput);
