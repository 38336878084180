import React from 'react';

function ContactUs() {
  return (
    <div style={styles.emailStyle}>
      support@divine.games
    </div>
  );
}

const styles = {
  emailStyle: {
    fontFamily: 'Elemental End',
    fontSize: '1.5em',
  },
};

export default ContactUs;
