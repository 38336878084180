import React from 'react';
import NavbarItem from './NavbarItem';
import BrandIcon from '../resources/icons/HR LOGO PNG-10.svg';

const navigationIndex = [
  {
    title: 'LOGIN',
    to: '/',
  },
  // {
  //   title: 'SIGN Up',
  //   to: '/register',
  // },
  {
    title: 'AbOUT',
    to: '/about',
  },
  {
    title: 'CONTACT',
    to: '/contact',
  },
];

function Navbar() {
  const navItems = navigationIndex.map((item, index) => <NavbarItem key={`${item.title + index}`} item={item} />);

  return (
    <div style={styles.navbarContainer}>
      <img style={styles.brandIcon} src={BrandIcon} alt="Brand Icon" />
      {navItems}
    </div>
  );
}

const styles = {
  navbarContainer: {
    width: '100%',
    height: '105px',
    backgroundColor: 'transparent',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    zIndex: 5,
    position: 'fixed',
    top: 0,
  },
  brandIcon: {
    padding: '36px 52px',
    filter: 'brightness(0) saturate(100%) invert(27%) sepia(36%) saturate(3229%) hue-rotate(331deg) brightness(109%) contrast(109%)',
  },
};

export default Navbar;
