import React from 'react';
import FormHeaderLinks from '../../components/authentication-flow/form-header/FormHeaderLinks';
import BackgroundLayer from '../../components/layout/BackgroundLayer';
import FadeTransition from '../../components/transitions/FadeTransition';
import Navbar from '../../navigator/Navbar';

import DivineGamesBranding from '../../resources/branding/LR LOGO PNG-02.png';

function HomePageFull({ currentPath, inputForm }) {
  return (
    <BackgroundLayer>
      <Navbar />
      <div style={styles.row}>
        <div style={styles.halfBlock}>
          <img style={styles.brandingImage} src={DivineGamesBranding} alt="Divine Games Branding" />
        </div>
        <div style={styles.halfBlock}>
          {/* <FormHeaderLinks currentPath={currentPath} /> */}
          <FadeTransition>
            {inputForm}
          </FadeTransition>
        </div>
      </div>
    </BackgroundLayer>
  );
}

const styles = {
  row: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    zIndex: 5,
  },
  halfBlock: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    color: 'white',
  },
  brandingImage: {
    width: '40%',
  },
};

export default HomePageFull;
