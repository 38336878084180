import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';
import NextButton from '../../button/NextButton';
import TextInput from '../../inputs/TextInput';

import mailIcon from '../../../resources/icons/mail-icon.svg';
import keyIcon from '../../../resources/icons/key-solid.svg';
import FormContainer from '../../inputs/FormContainer';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();

  const handleNextClick = () => {
    history.push('/verification');
  };

  return (
    <div style={{
      display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%',
    }}
    >
      <FormContainer>
        <TextInput
          icon={mailIcon}
          type="text"
          value={email}
          placeholder="Your Email"
          handleTextChange={setEmail}
        />
        <TextInput
          icon={keyIcon}
          type="password"
          value={password}
          placeholder="Your Password"
          handleTextChange={setPassword}
        />
      </FormContainer>
      <NextButton onClick={handleNextClick} />
    </div>
  );
}

export default Login;
