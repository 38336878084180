import React from 'react';
import {
  BrowserRouter as Router, Redirect, Route, Switch,
} from 'react-router-dom';
import HomePage from '../pages/HomePage';

function AppNavigator() {
  return (
    <Router>
      <Switch>
        <Route exact key="default-page" path="/" component={HomePage} />
        <Route exact key="login-page" path="/login" component={HomePage} />
        <Route key="register-page" path="/register" component={HomePage} />
        <Route key="verification-page" path="/verification" component={HomePage} />
        <Route key="qrcode-page" path="/qrcode" component={HomePage} />
        <Route key="about-page" path="/about" component={HomePage} />
        <Route key="contact-page" path="/contact" component={HomePage} />
        <Redirect to="/" />
      </Switch>
    </Router>
  );
}

export default AppNavigator;
